$orange: #ff8a00;
$red: #fe5105;
$blue: #4463ff;

html {
  font-size: 16px;
}

html,
body {
  height: 100%;
  background: #f8f9fa;
  font-family: "Roboto", sans-serif;
  color: #78909c;
  scroll-behavior: smooth;
}

body {
  margin: 0;

  font-size: 14px;
}
.text-orange {
  color: $orange !important;
}
.btn-round {
  border-radius: 100%;
}
.btn-fixed-bottom {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 20px;
  z-index: 99;
}
.breadcrumb {
  background-color: transparent;
  .breadcrumb-item {
    font-style: italic;
    color: #aaaaaa;
  }
}

h1 {
  font-size: 2.2rem;
  color: darkgrey;
  margin-bottom: 20px;
}
h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #616161;
}
// .card,
// table,
// .btn,
// input,
// select {
//   box-shadow: 0 4px 10px 1px #ececec;
// }

table {
  background: white;
}

nav.navbar {
}

.simulation {
  input,
  label,
  .input-group-text,
  .btn-outline-info {
    font-size: 13px;
  }
  .form-group {
    margin-bottom: 5px;
  }
  input,
  select,
  .input-group-text {
    padding: 0 5px;
    height: 30px;
  }
  .btn-outline-info {
    background-color: white !important;
  }
}

.btn-sm {
  font-size: 12px;
  padding: 2px 7px !important;
  line-height: 18px !important;
}
.btn-md {
  font-size: 16px;
  padding: 7px 10px !important;
  line-height: 16px !important;
}

.hideoverlay {
  position: absolute;
  top: 0;
  left: 0;
  background: repeating-linear-gradient(
    45deg,
    #ffffff,
    #ffffff 10px,
    #f7f7f7 10px,
    #f6f6f6 20px
  );
  width: 100%;
  height: 100%;
  z-index: 3;
  opacity: 0.5;
}

.overlay-header {
  background: $orange;
  color: white;
  font-size: 16px;
}
.head-sm {
  background: white;
  th {
    border-top: 0px;
  }
}
.head-sm tr.scroll {
  position: absolute;
  z-index: 99;
}

.big-table .quotation-table input {
  border: 0;
  box-shadow: none;
  padding: 0;
  height: auto;
  border-bottom: 1px solid #dcdcdc;
  border-radius: 0;
  text-align: center;
}
.big-table .quotation-table input:active,
.big-table .quotation-table input:focus {
  border: 0;
  border-bottom: 1px solid $orange;
  color: $orange;
}
.big-table .quotation-table th {
  color: #656565;
}
.detail-table {
  input {
  }
}

label {
  display: block;
  margin-bottom: 5px;
  line-height: 21px;
  font-weight: 400;
}
.form-control {
  height: 44px;
  margin-bottom: 0px;
  border: 1px solid #78909c;
  border-radius: 8px;
  color: #78909c;
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid $orange;
}

.big-table .quotation-table {
  .input-group span.input-group-text {
    padding: 0;
    background: transparent;
    border: 0;
    font-size: 12px;
    padding-left: 3px;
    color: #027bff !important;
  }
  .input-group .btn-outline-info {
    padding: 0;
    border: 0;
    margin-left: 0.25rem !important;
  }
  .input-group .btn-outline-info .fa {
    font-size: 8px;
    margin-left: 4px !important;
  }
  td {
    vertical-align: top !important;
    padding-left: 5px;
    padding-right: 10px;
    padding-bottom: 0;
  }
  .form-control:disabled {
    color: #9a9a9a;
    background: transparent;
    border: 0;
  }
}

.custom-panel.fadeIn {
  -webkit-animation: fadein 0.6s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.6s; /* Firefox < 16 */
  -ms-animation: fadein 0.6s; /* Internet Explorer */
  -o-animation: fadein 0.6s; /* Opera < 12.1 */
  animation: fadein 0.6s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.custom-panel .close-btn {
  float: right;
  width: auto;
  padding: 3px 8px;
}
.inline-table {
  td {
    padding-bottom: 0;
  }
}
.quotation-table td.backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding: 0;
  left: 0;
  background: repeating-linear-gradient(
    45deg,
    #ffffff,
    #ffffff 10px,
    #f7f7f7 10px,
    #f6f6f6 20px
  );
  z-index: 9;
  opacity: 0.5;
}
.inp-sm {
  height: 25px;
}
.tr-front {
  z-index: 99;
  position: relative;
  background: transparent;
  left: 0;
}

button,
a,
.form-control {
  transition: all 0.2s ease-in-out !important;
  -webkit-transition: all 0.2s ease-in-out !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: Roboto, sans-serif;
  color: $orange;
  font-weight: 300;
}

h1 {
  font-size: 34px;
  line-height: 34px;
  font-weight: 600;
}

h2 {
  font-size: 28px;
  line-height: 28px;
  font-weight: 500;
}
h3 {
  font-size: 26px;
  line-height: 26px;
}
h4 {
  font-size: 22px;
  line-height: 24px;
}
h5 {
  font-size: 18px;
  line-height: 20px;
}
h6 {
  font-size: 14px;
  line-height: 18px;
}

.table thead {
}
.table thead th {
  color: $red;
  font-weight: 400;
  border-bottom: 1px solid $red;
}
.table td {
  border-bottom: 1px solid rgba(255, 138, 0, 0.2);
  border-top: 0;
}

.btn {
  padding: 10px 20px;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  border-style: none;
  border-width: 0px;
  border-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  background-color: transparent;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #000;
  line-height: 21px;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #cccccc;
  margin-bottom: 2px;
  transform: skew(352deg, 0deg);
}
.btn:hover,
.btn:active {
  background-color: transparent;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
.btn-primary,
.btn-secondary {
  box-shadow: 0px 0px 4px -1px grey;
}
.btn-default {
  box-shadow: 0px 0px 4px -2px grey;
}
.btn-primary,
.btn-primary:hover,
.btn-primary:active {
  background-image: linear-gradient(306deg, #f39123, #fe5105);
  color: #fff;
  border: 0;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: linear-gradient(306deg, #f39123, #fe5105);
  border-color: $orange;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active {
  background-color: $orange;
  color: #fff;
  border: 0;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: $orange;
  border-color: $orange;
}

.btn-success,
.btn-success:hover,
.btn-success:active {
  background-image: linear-gradient(149deg, #70bcff, #4463ff);
  color: #fff;
  border: 0;
}

.btn-danger,
.btn-danger:hover,
.btn-danger:active {
  background-image: linear-gradient(135deg, #ff5a51, #f30);
  -webkit-transition: opacity 200ms ease, background-color 200ms ease;
  transition: opacity 200ms ease, background-color 200ms ease;
  color: #fff;
  border: 0;
}

.btn-outline-info,
.btn-outline-info:hover,
.btn-outline-info:active {
  border: 1px solid #cccccc;
  color: #333333;
  margin-bottom: 0;
}

.input-group .btn-outline-info {
  padding: 0 10px;
}

.badge-danger {
  background-image: linear-gradient(135deg, #ff5a51, #f30);
  -webkit-transition: opacity 200ms ease, background-color 200ms ease;
  transition: opacity 200ms ease, background-color 200ms ease;
  color: #fff;
}

a {
  color: $orange;
}

.nav.nav-tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #ff8a00;
}
.nav-tavs .nav-item {
  padding-top: 12px;
  padding-bottom: 12px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background-color: transparent;
  color: #ff8a00;
  text-align: center;
  text-transform: uppercase;
  background-color: white;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 0px solid #000;
  background-color: #fff;
  background-image: linear-gradient(304deg, #f39123, #fe5105);
  color: #fff;
  font-weight: 400;
}

.nav-tabs .nav-link.active:hover {
  color: white;
}
.nav-tabs .nav-link,
.nav-tabs .nav-link:hover {
  height: 100%;
  color: $orange;
  border-bottom: 1px solid $orange;
  text-transform: uppercase;
}

.text-success {
  color: $blue;
}
.text-danger {
  color: $red;
}

.overlay-content {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: white;
  z-index: 999;
}

.input-lock {
  position: absolute;
  top: 3px;
  right: 7px;
  font-size: 11px;
}

.quotation-table tbody td {
  border-bottom: 0;
}

@media print {
  .no-print {
    visibility: hidden;
  }

  button {
    display: none;
  }
  @page {
    size: landscape;
  }
}
.Toastify__toast-body {
  font-weight: 500;
  font-size: 18px;
}
.Toastify__toast {
  border-radius: 20px !important;
  min-height: 45px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.bg-light-orange {
  background-color: #f6f8ff !important;
}

.custom-gap .item {
  margin-right: 10px; /* Add desired gap value */
}

/* To remove margin from the last item */
.custom-gap .item:last-child {
  margin-right: 0;
}
.bottom-shadow {
  position: relative;
  &::after {
    content: "";
    height: 40px;
    background: red;
  }
}
.text-orange {
  color: #fe5105;
}

.brand-logo {
  margin-right: 5px;
}
